import React from "react"
import "./sectionHowItWorks.scss"
import {StaticImage} from "gatsby-plugin-image";
import ButtonBuyNow from "../buttons/ButtonBuyNow";


class SectionHowItWorks extends React.Component {

    render() {
        return (

            <div className="sectionHowItWorks">
                <div className="copyTitle">
                    <h2>How it works</h2>
                </div>
                <div className="steps">
                    <ol>
                        <li>Download the app & connect the patch</li>
                        <li>Have your child wear the Welltot sensor (either by the chest harness or the adhesive tabs)</li>
                        <li>Place a phone (iOS or Android) with the app installed by the child to receive the readings</li>
                        <li>Monitor your child's temperature from any iOS or Android phone*</li>
                    </ol>
                    <ButtonBuyNow/>
                </div>
                <div className="copyFootnote">
                    <small>*This can be the same phone as the one near the child, or multiple phones logged into the same account.</small>
                </div>
                <div className="howItWorksImage">
                    <StaticImage
                        src="../../assets/images/page-images/how-it-works.png"
                        alt="Features"
                        placeholder="blurred"
                        layout="fullWidth"
                        quality={100}
                    />
                </div>

            </div>
        )
    }
}

export default SectionHowItWorks






