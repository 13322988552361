import React from "react"
import "./sectionQuote1.scss"
import {getImage, StaticImage} from "gatsby-plugin-image";
import BackgroundImage from "gatsby-background-image";
import {graphql, PageProps, useStaticQuery} from "gatsby";
import {convertToBgImage} from "gbimage-bridge";


const SectionQuote1 = (props: PageProps) => {
    const data = useStaticQuery(query)
    const backgroundImage = convertToBgImage(getImage(data.placeholderImage ));

    return (
        <div className="sectionQuote1">
            <BackgroundImage
                {...backgroundImage}
                style={{ backgroundRepeat: 'repeat', backgroundSize: '10%'}}
                preserveStackingContext
                className="quoteBackgroundImage"
            >
                <div className="quoteImage">
                    <StaticImage
                        src="../../assets/images/page-images/quote1.png"
                        alt="Features"
                        placeholder="blurred"
                        layout="fullWidth"
                        quality={100}
                    />
                </div>
                <div className="quoteCopy">
                    <blockquote>&ldquo;I am glad I didn't have to wake my daughter again to take her temperature.&rdquo;</blockquote>
                </div>
                <div className="byLine">
                    <p>Heather, mom of a 2-year-old</p>
                </div>
            </BackgroundImage>
        </div>
    )
};


const query = graphql`
    query {
      placeholderImage: file(
        sourceInstanceName: {eq: "images"}
        relativePath: {eq: "background/pattern_repeater1.png"}
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP, AVIF])
        }
      }
    }
`;




export default SectionQuote1






