import React from "react"
import "./sectionBuyNow.scss"
import {StaticImage} from "gatsby-plugin-image";
import ButtonBuyNow from "../buttons/ButtonBuyNow";


class SectionBuyNow extends React.Component {

    render() {
        return (
            <div className="sectionBuyNow">
                <div className="buyNowBackground">
                </div>
                <div className="copyHeader">
                    <h2>Get your Welltot monitor</h2>
                </div>
                <div className="copyText">
                    <ul>
                        <li><span className="alt">50+ temperature readings</span> per hour</li>
                        <li><span className="alt">Disposable</span> patch</li>
                        <li><span className="alt">No maintenance</span> & no re-charging</li>
                        <li><span className="alt">Easy to use</span> - mobile phone connected</li>
                    </ul>
                </div>
                <div className="sensorImage">
                    <StaticImage
                        src="../../assets/images/page-images/sensorWelltot.png"
                        alt="Features"
                        placeholder="blurred"
                        layout="fullWidth"
                        quality={100}
                    />
                </div>
                <div className="buttonBuyNow">
                    <ButtonBuyNow/>
                </div>
            </div>
        )
    }
}

export default SectionBuyNow

