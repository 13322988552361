import React from "react"
import "./sectionFeatures.scss"
import { StaticImage } from "gatsby-plugin-image"
import ButtonBuyNow from "../buttons/ButtonBuyNow";


class SectionFeatures extends React.Component {

    render() {
        return (

            <div className="sectionFeatures">
                <div className="containerFeatures">
                    <div className="mainImage">
                        <StaticImage
                            src="../../assets/images/page-images/in-bed-wearing-sensor.jpg"
                            alt="Features"
                            placeholder="blurred"
                            layout="fullWidth"
                            quality={100}
                        />
                    </div>
                    <div className="featuresQuote">
                        <blockquote>Continuous temperature<br/>tracking allows you to see<br/>the <strong>trends, spikes, and dips.</strong></blockquote>
                    </div>
                    <div className="featuresCopy">
                        <div className="copyTitle">
                            <h2>Features</h2>
                        </div>
                        <ul>
                            <li>Over 50 trended temperature readings per hour</li>
                            <li>Mobile phone connected</li>
                            <li>6-month disposable patch</li>
                            <li>Made in the USA</li>
                        </ul>
                        <ButtonBuyNow/>
                    </div>
                </div>
            </div>
        )
    }
}

export default SectionFeatures






