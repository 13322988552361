import { graphql, PageProps } from 'gatsby';
import React from 'react';
import LayoutMain from '../components/layouts/LayoutMain';
import SectionBillboard from '../components/sectionBillboard/SectionBillboard';
import SectionBuyNow from '../components/sectionBuyNow/SectionBuyNow';
import SectionHowItWorks from '../components/sectionHowItWorks/SectionHowItWorks';
import SectionQuote1 from '../components/sectionQuote1/SectionQuote1';
import SectionRtb from '../components/sectionFeatures/SectionFeatures';
import SectionWhyChooseWelltot from '../components/sectionWhyChooseWelltot/SectionWhyChooseWelltot';
import SEO from '../components/seo/SEO';
import '../styles/homepage.scss';
import SectionTotLifeSummary from "../components/TotLife/SectionTotLifeSummary";


const IndexPage = ({ data }: PageProps) => {
  return (
    <LayoutMain>
      <SEO bodyClassname="homepage" />

      <div className="container-homepage">
        <div className="homepage-sectionBillboard">
          <SectionBillboard />
        </div>
        <div id="features" className="homepage-sectionRtb">
          <SectionRtb />
        </div>
        <div className="homepage-sectionWhyChooseWelltot">
          <SectionWhyChooseWelltot />
        </div>
        <div className="homepage-sectionHowItWorks">
          <SectionHowItWorks />
        </div>
        <div className="homepage-sectionQuote1">
          <SectionQuote1 />
        </div>
        <div className="homepage-sectionTotLifeSummary">
          <SectionTotLifeSummary/>
        </div>
        <div className="homepage-sectionBuyNow">
          <SectionBuyNow />
        </div>
      </div>
    </LayoutMain>
  );
};


export default IndexPage;
