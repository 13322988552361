import React from 'react';
import {graphql, PageProps, useStaticQuery} from "gatsby";
import './SectionTotLifeSummary.scss';
import ArticleSummaryBlocks from "./ArticleSummary-Blocks";


const SectionTotLifeSummary = (props: PageProps) => {
  const data = useStaticQuery(query);

  return (
    <div className="sectionTotLifeSummary">
        <div className="copyTitle">
            <h2>Tot-Life</h2>
            <h3>A Welltot Magazine</h3>
        </div>

        <div className="articleSummaries">
          <ArticleSummaryBlocks
              articles={data.allPrismicWelltotBlogBlog.edges}
          />

        </div>
    </div>
  );
};

const query = graphql`
query {
  allPrismicWelltotBlogBlog(
    limit: 6
    sort: {fields: last_publication_date, order: DESC}
  ) {
    edges {
      node {
        url
        data {
          title {
            text
          }
          blog_image {
            alt
            gatsbyImageData(width: 400, aspectRatio: 1.333)
          }
        }
      }
    }
  }
}
`;

export default SectionTotLifeSummary;
