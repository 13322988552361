import React from 'react';
import ArticleSummaryBlock from './ArticleSummary-Block';
import * as styles from './ArticleSummary-Blocks.module.scss';


const ArticleSummaryBlocks = ({ articles }) => {
  return (
    <div className={styles.container}>

        {articles.map((article, index) => (
            <div className={styles.block}>
              <ArticleSummaryBlock
                key={index}
                title={article.node.data?.title?.text ?? ''}
                imageAlt={article.node.data?.blog_image?.alt ?? ''}
                imageData={article.node.data?.blog_image?.gatsbyImageData}
                url={article.node.url!}
              />
            </div>
        ))}

    </div>
  );
};

export default ArticleSummaryBlocks;



