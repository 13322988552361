import { navigate } from 'gatsby';
import {GatsbyImage, getImage, IGatsbyImageData} from 'gatsby-plugin-image';
import React from 'react';
import * as styles from './ArticleSummary-Block.module.scss';

type Props = {
  title: string;
  imageData?: IGatsbyImageData;
  imageAlt: string;
  url: string;
};

const ArticleSummaryBlock = ({ title, url, imageData, imageAlt }: Props) => {
  const image = imageData ? getImage(imageData) : undefined;

  return (
      <div className={styles.card}>
          <div className={styles.image}>
              {image && <GatsbyImage alt={imageAlt} image={image} />}
          </div>
          <div className={styles.copy}>
              <h3>{title}</h3>
          </div>
          <div className={styles.buttonBar}>
              <button onClick={()=>{navigate(url)}}>Read</button>
          </div>
      </div>
  );
};

export default ArticleSummaryBlock;
