import React from "react"
import * as styles from "./ButtonBuyNow.module.scss"

class ButtonBuyNow extends React.Component {
    render() {
        return (
            <div className={styles.buttonBuyNow}>
                <a href="https://forms.gle/47CQ5MfeavwbLFxA8" target="_blank" rel="noreferrer"><button className={styles.button}>Buy</button></a>
            </div>
        )
    }
}

export default ButtonBuyNow

