import React from "react"
import "./sectionBillboard.scss"
import { StaticImage } from "gatsby-plugin-image"
import ButtonBuyNow from "../buttons/ButtonBuyNow";


class SectionBillboard extends React.Component {

    render() {
        return (

            <div className="sectionBillboard">
                <div className="img1">
                    <StaticImage
                        src="../../assets/images/page-images/mom-and-child.jpg"
                        alt="Welltot"
                        placeholder="blurred"
                        layout="fullWidth"
                        quality={100}
                    />
                </div>
                <div className="copyBillboard">
                    <h2>Compassionate Love</h2>
                    <h1>Welltot supports you with<br/>continuous temperature<br/> monitoring, so you can focus<br/>on <span className="lighter">being your child's mom.</span></h1>
                    <div className="BuyNowBillboard">
                        <ButtonBuyNow/>
                    </div>
                </div>
            </div>

        )
    }
}

export default SectionBillboard






